(function () {
    'use strict';
    $(document).ready(function () {

        // Smooth scroll to
        $('.scroll-animation').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');
            if (href) {
                $("html, body").animate({
                    scrollTop: $(href).offset().top
                }, 1000);
            }
        });

        $('.scroll-animation a').on('click', function (e) {
            e.stopPropagation();
        });

        $(".disclaimer-text").mCustomScrollbar({
            axis: "y",
            mouseWheel: {
                enable: true
            }
        });

        $(".disclaimer-accept").on('click', function (e){
            e.preventDefault();
            $('.disclaimer').fadeOut(500, function(){
                $(this).remove();
            });
        });

        //Menu mobile toggle
        $(".menu-toggle").click(function(){
            $('.menu-overlay.nav').slideToggle();
            $(this).toggleClass("open");
        });

        $(".header #site-menu li.menu-item-has-children > a").on('click', function (e) {
            e.preventDefault();
        });

        $(".header #site-menu li.menu-item-has-children > ul," +
            ".header #site-menu li.menu-item-has-children > ul > li").on('click', function (e) {
            e.stopPropagation();
        });

        $(".header #site-menu > li.menu-item-has-children").click(function () {
            $(this).toggleClass('open');
            $(this).children('ul').slideToggle({
                start: function () {
                    $(this).css({
                        display: "flex"
                    })
                }
            });
            $(this).siblings('li').children('ul').slideUp();
            $(this).siblings('li').removeClass('open');
        });

        $(document).click(function (e) {
            var container = $(".header #site-menu");
            if (container.has(e.target).length === 0) {
                $(".header #site-menu").find("li.menu-item-has-children ul").slideUp();
                $(".header #site-menu").find("li.menu-item-has-children").removeClass('open');
            }
        })

        //Search trigger
        $('.search-trigger').click(function () {
            $(this).toggleClass('active');
            $('.search-panel').fadeToggle('active');
            return false;
        });

    });
}(jQuery));
