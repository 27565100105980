(function () {
    'use strict';
    $(document).ready(function () {

        $(".play-video").modalVideo({
            channel: 'vimeo'
        });

    });
}(jQuery));
