(function () {
    'use strict';
    $(function() {
        $(document).ready(function () {

            $('input.datepicker').datepicker({
                format: 'dd/mm/yyyy',
                language: 'pl',
                clearBtn: true,
                autoclose: true
            });

            $('label.date').click(function(e) {
                e.preventDefault();
            });

            $(".reports .detailed-filter .search").click(function(event){
                event.preventDefault();
                $(".reports .filter a").removeClass('active');

                var keyword = $("#keyword").val();
                var number = $("#number").val();
                var dateFrom = $("#date-from").val();
                var dateTo = $("#date-to").val();

                var tableRows = $("table tbody tr");
                tableRows.hide();
                tableRows.each(function(){
                    var correct = true;

                    if(keyword !== ""){
                        var content = $(this).text();
                        content = content.toLowerCase();
                        keyword = keyword.toLowerCase();
                        if(content.indexOf(keyword) === -1){
                            correct = false;
                        }
                    }

                    if ( ($("#number").length) && (number !== "")){
                        var content = $(this).find(".number").text();
                        content = content.toLowerCase();
                        number = number.toLowerCase();
                        if(content.indexOf(number) === -1){
                            correct = false;
                        }
                    }

                    if(dateFrom !== ""){
                        var date = moment($(this).data('date'), 'DD/MM/YYYY');
                        var inputDate = moment(dateFrom, 'DD/MM/YYYY');

                        if(date < inputDate){
                            correct = false;
                        }
                    }

                    if(dateTo !== ""){
                        var date = moment($(this).data('date'), 'DD/MM/YYYY');
                        var inputDate = moment(dateTo, 'DD/MM/YYYY');

                        if(date > inputDate){
                            correct = false;
                        }
                    }

                    if(correct){
                        $(this).show();
                    }
                });

                event.preventDefault();
                return false;
            });

            $(".reports .detailed-filter .clear").click(function(event){
                event.preventDefault();
                $(".reports .filter a:first-child").addClass('active');
                var year = $(".reports .filter a:first-child").data('year');

                $("table tbody tr").hide();
                $("table tbody tr[data-year='" + year + "']").show();

                $("#keyword, #number, #date-from, #date-to").val('');

                event.preventDefault();
                return false;
            });

        });
    });
}(jQuery));
